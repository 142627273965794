import { RouteHandler } from 'wee-routes';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import $events from 'wee-events';
import $ from 'wee-dom';
import { _win } from 'core/variables';
import { active, noAnimation } from '../../scripts/classes';

export function handleSlideOver(e, el, animate = true) {
    const $el = $(el);
    const type = $el.data('type');
    const $slideOver = $(':slideOver')
        .filter((i, slideOver) => $(slideOver).data('type') === type);
    const $activeSlideovers = $(':slideOver')
        .filter((i, slideOver) => $(slideOver).hasClass(active));

    if (animate) {
        $slideOver.toggleActivate();
    } else {
        $slideOver.addClass(noAnimation)
            .toggleActivate();

        setTimeout(() => {
            $slideOver.removeClass(noAnimation);
        }, 100);
    }

    $slideOver.css({
        zIndex: parseInt($slideOver.css('zIndex')) + $activeSlideovers.length,
    });

    if ($slideOver.isActive()) {
        disableBodyScroll();
        _win.location.hash = type;
    } else {
        enableBodyScroll();
        _win.history.replaceState('', '', _win.location.href.replace(/#.*$/, ''));
    }
}

export default new RouteHandler({
    init() {
        $events.on({
            ':slideOverToggle': {
                click: handleSlideOver,
            },
            ':slideOverClose': {
                click: handleSlideOver,
            },
        });
    },
});
