import $router from 'wee-routes';
import $ from 'wee-dom';
import './bootstrap';
import { _win } from '@weepower/core/scripts/core/variables';
import { handleSlideOver } from '../components/slide-over';

const common = [
    () => import('../components/topic-bar').then((m) => m.default || m),
    () => import('../components/header').then((m) => m.default || m),
    () => import('../components/slide-over').then((m) => m.default || m),
    () => import('../components/subscribe').then((m) => m.default || m),
    () => import('../components/blog/entries').then((m) => m.default || m),
];

if (_win.location.hash.length) {
    const slideOverEl = $(':slideOver')
        .filter((i, el) => $(el).data('type') === window.location.hash.replace('#', ''))[0];

    handleSlideOver(null, slideOverEl, false);
}

$router.map([
    {
        path: '/',
        handler: [
            ...common,
            () => import('../components/blog').then((m) => m.default || m),
        ],
    },
    {
        path: '/category/:category',
        handler: [
            ...common,
            () => import('../components/blog').then((m) => m.default || m),
        ],
    },
    {
        path: '/blog/:blog',
        handler: [
            ...common,
        ],
    },
    {
        path: '/about',
        handler: [
            ...common,
        ],
    },
    {
        path: '/contact',
        handler: [
            ...common,
        ],
    },
]).run();
